import * as actionTypes from '../action/action';

const initialState = {
    sidebarShow: true,
    theme: "light",
};

const typeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TYPE_SIDEBAR:
            return {
                ...state,
                sidebarShow: action.sidebarShow
            };
        default:
            return state;
    }
};
export default typeReducer;