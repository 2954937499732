/* eslint-disable */
import Cookies from "js-cookie";
import axios from "axios";
import getAlias from '../config/ScreenAlias';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  // withCredentials: true
});

const pathName = window.location.pathname;

instance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("ACCESS_TOKEN");
    config.headers.Authorization = token ? "Bearer " + token : "";
    config.headers["menu-alias"] = getAlias(window.location.pathname);
    // if (config.headers["Content-Type"] === "multipart/form-data") {
    //   for (const pair of config.data.entries()) {
    //     if (["value", "image", "file_0"].includes(pair[0]) && pair[1]?.size) {
    //       config.headers.filesize = pair[1].size / (1024 * 1024);
    //     }
    //   }
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Thrown error for request with OK status code
    const { data } = response;
    // const originalRequest = response.config;

    // const isExpAccessToken = data.code === 401 && data.data === "isUser";
    // if (isExpAccessToken) {
    //   console.log("is user");
    //   // Is auto call get new access and refresh token
    //   if (data.isAuto) {
    //     originalRequest._retry = true;
    //     return userService
    //       .getNewAccessAndRefreshToken()
    //       .then(({ accessToken }) => {
    //         // Set Authorization Header new value
    //         axios.defaults.headers.common["Authorization"] = accessToken;
    //         return instance(originalRequest);
    //       })
    //       .catch((error) => {
    //         const logout = () => ({
    //           type: userConstants.LOGOUT,
    //           payload: {},
    //         });
    //         store.dispatch(logout());
    //         Cookies.remove(storageConstant.ACCESS_TOKEN);
    //         Cookies.remove(storageConstant.REFRESH_TOKEN);
    //         const tokenExpired = () => ({
    //           type: userConstants.REFRESH_TOKEN_FAILURE,
    //           payload: {},
    //         });
    //         store.dispatch(tokenExpired());
    //         return Promise.reject(error);
    //       });
    //   }
    // }

    return response;
  },
  (error) => {
    if (error?.response.status === 401) {
      const idFormPrint = localStorage.getItem("ID_FORM") ?? '1';
      Cookies.remove("ACCESS_TOKEN");
      localStorage.clear();
      localStorage.setItem("ID_FORM", idFormPrint);
      window.location.href = '/login';
    }
    return error?.response?.data;

    // if (error.code === "ECONNABORTED") {
    //   return Promise.reject(error);
    // }
    // const { response } = error;

    // if (response) {
    //   const { data } = response;
    //   if (data?.message) {
    //     const error = new Error(data?.message);
    //     error.status = response.status;
    //     error.data = data.data;
    //     return Promise.reject(error);
    //   }
    //   return Promise.reject(error);
    // }
    // return Promise.reject({
    //   status: 500,
    //   message: "Đã có lỗi xảy ra",
    //   data: {},
    // });
  }
);

export default instance;
