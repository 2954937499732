import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate  } from "react-router-dom";
import Cookies from "js-cookie";
import "./scss/style.scss";
import AnonymousRoute from "../src/container/AnonymousRoute";
import axiosApi from './api/axios';
import routerApi from './api/router';
import { useDispatch } from 'react-redux';
import { DATA_USER } from './redux/action/action';

const Login = React.lazy(() => import("./views/pages/login/login"));
const MainLayout = React.lazy(() => import("./container/content/layouts"));
const SplashScreen = React.lazy(() => import("./views/pages/splash/splash"));
const loading = (
	<div className="pt-3 text-center">
	  <div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const App = () => {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(true);
	const [isAuthorizated, setAuthorization] = useState();
	const [isAuthenticated, setAuthenticated] = useState(false);

	const onGetDetail = async () => {
		const data = await axiosApi.get(routerApi.SUB_URL.profileApi);
		dispatch({ type: DATA_USER, dataUser: data.data });
		localStorage.setItem("DATA_USER", JSON.stringify(data.data));
	}

	useEffect(() => {
		if (isLoading) {
			if (Cookies.get("ACCESS_TOKEN")) {
				onGetDetail();
				setLoading(false);
			} else {
				const idFormPrint = localStorage.getItem("ID_FORM") ?? '1';
				localStorage.clear();
				localStorage.setItem("ID_FORM", idFormPrint);
			}
		} else {
			setAuthorization(false);
			setLoading(false);
		}
		
	  }, [isAuthorizated]);
	
	// if (isLoading) {
	// 	return (
	// 		<Suspense fallback={loading}>
	// 			<SplashScreen />
	// 		</Suspense>
	// 	);
	// }
	
	return (
		<BrowserRouter>
			<Suspense fallback={loading}>
				<Routes>
					<Route
						path="/login"
						name="Login Page"
						element={
							<AnonymousRoute>
								<Login />
							</AnonymousRoute>
						}
					/>
					<Route path="*" name="Dashboard" element={<MainLayout />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
