import { Navigate } from "react-router-dom";

const AnonymousRoute = ({ children }) => {
    const user = localStorage.getItem("DATA_USER");
    if (user) {
        return <Navigate to="/dashboard" />;
    }
    return children;
};

export default AnonymousRoute;