
import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import typeReducer from "./type.reducer";

const reducer = combineReducers({
    auth: authReducer,
    dataUser: userReducer,
    typeReducer: typeReducer,
});

export default reducer;