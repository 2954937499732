const ScreenALias = [
    {
        screen: "/user/detail_user",
        alias: "menu.user_setting",
        name: "Thông tin tài khoản",
    },
    {
        screen : "/user/list_users",
        alias: 'menu.user',
        name: "DS tài khoản máy chủ",
    },
    {
        screen : "/system/master",
        alias: 'menu.master',
        name: "Danh sách dùng chung",
    },
    {
        screen : "/stations/list-station",
        alias: 'menu.station',
        name: "Danh sách đơn vị",
    },
    {
        screen: "/form/list_forms",
        alias: 'menu.station_form',
        name: "Phiếu cân"
    },
    {
        screen: "/system/system_log",
        alias: 'menu.log',
        name: "Nhật ký hệ thống"
    },
    {
        screen: "/user/list_accounts",
        alias: 'menu.station_user',
        name: "DS tài khoản phần mềm"
    },
    {
        screen: "/stations/info-station",
        alias: "menu.my_station",
        name: "Đơn vị trực thuộc",
    },
    {
        screen: "/dashboard",
        alias: "menu.dashboard",
        name: "Thông kê chung",
    }, 
    // {
    //     screen: "/dashboard/statistic_detail",
    //     alias: "menu.dashboard_detail",
    //     name: "Thông kê chi tiết",
    // },
    {
        screen: "/form/master_station",
        alias: "menu.station_master",
        name: "Dữ liệu phiếu cân"
    },
    {
        screen: "/stations/setting_station",
        alias: "menu.station_setting",
        name: "Thiết bị kết nối"
    },
    {
        screen: "/system/system_log_station",
        alias: "menu.station_log",
        name: "Nhật ký phần mềm"
    },
    {
        screen: "/system/queue_log",
        alias: "menu.queue_log",
        name: "Nhật ký đồng bộ"
    }
];

const getAlias = (screen) => {
    
    const alias = ScreenALias.find((item) => item.screen === screen);
    return alias ? alias.alias : "";
};
  
export const getNameByAlias = (alias) => {
    const screen = ScreenALias.find((item) => item.alias === alias);
    return screen ? screen.name : "";
};

export default getAlias;
  