/* eslint-disable import/no-anonymous-default-export */
export default {
    SUB_URL : {
        loginAPI: '/login',
        logOutAPI: '/logout',
        profileApi: '/profile',
        dasboardStatisticsByDay: "/dashboards/scale-statistics-by-day",
        stationStatus: '/dashboards/station-status',
        latestWeigh: '/dashboards/get-latest-weigh',
        generalStatistic: '/dashboards/general-statistics',
        statisticType: '/dashboards/scale-statistics-by-type',
        weightHoutly: '/dashboards/get-weight-hourly',
        numberWeightHourly: '/dashboards/get-number-of-weigh-hourly',
        cameraStation: '/station-connections/camera/all',
        stationDropDown: '/stations/all-station',
        stationListForm: '/station-forms/list',
        listUser: '/users/list',
        licenseFile: '/licenses/get-license-file',
        permission: '/get-all-permission'
    }
};