import * as actionTypes from '../action/action';

export const initialState = {
    dataUser: {},
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_USER:
            return {
                ...state,
                dataUser: action.dataUser
            };
        default:
            return state;
    }
};
export default userReducer;